import { template as template_c587f5d9a927499dba87286ec09e6c74 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c587f5d9a927499dba87286ec09e6c74(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
