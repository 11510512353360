import { template as template_fb37d9af74ec4135bc801e0c26a88735 } from "@ember/template-compiler";
const FKLabel = template_fb37d9af74ec4135bc801e0c26a88735(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
